import { put, takeEvery } from "redux-saga/effects";
import { GET_BID, GET_BID_CREATE, GET_BID_CREATE_UPDATED, GET_BIDS, GET_COOKIE, GET_PROJECTS, GET_PROJECTS_ID, GET_USERS, SET_BID, SET_BID_CREATE, SET_BID_CREATE_UPDATED, SET_BIDS, SET_COOKIE, SET_PROJECTS, SET_PROJECTS_ID } from "../ConstAction";
import projectsApiRequests from "../../services/Projects";
import { toast } from "react-toastify";
function* getprojects(data) {
  ////console.log(data);
  
  try {
    let response = yield projectsApiRequests.getprojects(data.data);
    ////console.log(response);
    
      yield put({
        type: SET_PROJECTS,
        response,
      });
  } catch (err) {}
}
function* getprojectsid(data) {
  ////console.log(data);
  
  try {
    let response = yield projectsApiRequests.getprojectsid(data.data);
    ////console.log(response);
    
      yield put({
        type: SET_PROJECTS_ID,
        response,
      });
  } catch (err) {}
}
function* getbidposted(data) {
  ////console.log(data);
  
  try {
    let response
    if(data.data.id){
      response = yield projectsApiRequests.updatebid(data.data);
    }else
     response = yield projectsApiRequests.postbid(data.data);
    ////console.log(response);
    
      yield put({
        type: SET_BID_CREATE_UPDATED,
        response,
      });
      
      yield put({
        type: GET_BIDS,
        data: data.values,
      });
      setTimeout(() => {
        if(response?.data?.status){
        if(data.data.id){
        toast.success("Bid updated Successfully")
        }else{
          toast.success("Bid created Successfully")
        }}else{
          toast.error(response?.data?.message)
        }
      }, 2000);

  } catch (err) {}
}
function* getbid(data) {
  ////console.log(data);
  
  try {
    let response
      response = yield projectsApiRequests.getbid(data.data);
    ////console.log(response);
    
      yield put({
        type: SET_BID,
        response,
      });
      // yield put({
      //   type: GET_PROJECTS_ID,
      // });
  } catch (err) {}
}
function* getbids(data) {
  ////console.log(data);
  
  try {
    let response
      response = yield projectsApiRequests.getbids(data.data);
    ////console.log(response);
    if(response){
      yield put({
        type: SET_BIDS,
        response,
      });
      yield put({
        type: GET_USERS,
      });}
  } catch (err) {}
}

function* getCookie(data) {
  try {
    let response = yield projectsApiRequests.cookie(data.data);
    //console.log(response);
    if(response){
    yield put({
        type: SET_COOKIE,
        response:response
      });}
  } catch (err) {

  }
}

function* projectSaga() {
  yield takeEvery(GET_PROJECTS, getprojects);
  yield takeEvery(GET_PROJECTS_ID, getprojectsid);
  yield takeEvery(GET_BID_CREATE_UPDATED, getbidposted);
  yield takeEvery(GET_BID, getbid);
  yield takeEvery(GET_BIDS, getbids);
  yield takeEvery(GET_COOKIE, getCookie);

}
export default projectSaga;
