import { put, takeEvery } from "redux-saga/effects";
import {
  GET_COOKIE,
  GET_LOADING,
  GET_LOGIN,
  GET_REGISTER,
  GET_USERS,
  SET_COOKIE,
  SET_LOADING,
  SET_LOGIN,
  SET_REGISTER,
  SET_USERS,
} from "../ConstAction";
import { storage } from "../../Config/Storage";
import { toast } from "react-toastify";
import UserApiRequests from "../../services/User";
import { getRegister } from "./UserAction";
function* getLogin(data) {
  try {
    let response = yield UserApiRequests.Login(data.data);
    ////console.log(response);
    
    if (response.status) {
      storage.set.authToken(response.token);
      data.navigate("/dashboard");
      yield put({
        type: SET_LOGIN,
        response,
      });
      setTimeout(() => {
        toast.success("User Logged in Successfully");
      }, 1000);
    }else{
      ////console.log(response);
    
      toast.error(response.message);
    }
  } catch (err) {
    toast.error(err.message);
   

  }
}
function* getRegistered(data) {
  try {
    let response = yield UserApiRequests.register(data.data);
    ////console.log(response);
    
    if (response.status) {
      data.navigate("/");
      yield put({
        type: SET_REGISTER,
        response,
      });
      setTimeout(() => {
        toast.success("User Registered in Successfully");
      }, 1000);
    }
  } catch (err) {

  }
}
function* getLoading(data) {
  try {
      yield put({
        type: SET_LOADING,
        response:data
      });
  } catch (err) {

  }
}
function* getUsers(data) {
  try {
    let response = yield UserApiRequests.users(data);
    //console.log(response);
    if(response){
    yield put({
        type: SET_USERS,
        response:response
      });}
  } catch (err) {

  }
}


function* userSaga() {
  yield takeEvery(GET_LOGIN, getLogin);
  yield takeEvery(GET_REGISTER, getRegistered);
  yield takeEvery(GET_LOADING, getLoading);
  yield takeEvery(GET_USERS, getUsers);
}
export default userSaga;
