import { GET_COOKIE, GET_LOADING, GET_LOGIN, GET_REGISTER, GET_USERS } from "../ConstAction";

export const getLogin = (data, navigate) => {
  return {
    type: GET_LOGIN,
    data: data,
    navigate: navigate,
  };
};
export const getRegister = (data, navigate) => {
  return {
    type: GET_REGISTER,
    data: data,
    navigate: navigate,
  };
};
export const getLoading = (data) => {
  return {
    type: GET_LOADING,
    data: data,
  };
};
export const users = (data) => {
  return {
    type: GET_USERS,
    data: data,
  };
};
