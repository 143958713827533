import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import Card from '../Components/Card';
import { ArrowUpward } from '@mui/icons-material';
import { API_URL } from '../Config/URL';
import { CSS } from '../Components/CSS';
import Toastify from '../Components/SnackBar/Toastify';
import LoaderComponent from '../Components/Loader/LoaderComponent';
import RefreshIcon from '@mui/icons-material/Refresh';
import SelectField from '../Components/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { Allprojects } from '../redux/Projects/ProjectsAction';
import Header from '../Components/Header';
import Heading from '../Components/Heading';

const Dashboard = () => {
  const { result, loading } = useSelector((state) => state?.ProjectData) || {};
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [results, setResults] = useState({});
  const [load, setLoad] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [alignment, setAlignment] = useState('All'); // Manage selected value
  const [values, setValues] = useState({
    page: 1,
    limit: 10,
    offset: 0,
    search:""
  });

  // Handle selection change
  const handleChange = (event) => {
    setAlignment(event.target.value);
    setLoaded(false);
    setValues({ ...values, offset: 0, page: 1 }); // Reset pagination
  
  };

  // Function to handle scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const totalHeight = document.documentElement.scrollHeight;
      const visibleHeight = window.innerHeight;
      const remainingSpace = totalHeight - (scrolled + visibleHeight);

      setVisible(scrolled > 300);

      if (remainingSpace < 10 && !load) {
        setLoad(true);
        setLoaded(true);
        setValues(prevValues => ({ ...prevValues, offset: prevValues.offset + 10 }));
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [load]);

  // Fetch more projects when values change (pagination)
  useEffect(() => {
    dispatch(Allprojects({ ...values, type: alignment }));
  }, [values, alignment]);

  useEffect(() => {
    if (result && loaded) {
      //console.log("result");
      
      setList(prevList => [...prevList, ...result?.projects]);
      setResults(prevResult => ({
        ...prevResult,
        ...result?.users
      }));
      setLoaded(true);
      setLoad(false);
    } else {
      setList(result?.projects);
      setResults(result?.users);
    }
  }, [result]);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Handle refresh
  const handleRefresh = () => {
    setAlignment("All"); // Reset to default value
    setLoaded(false);
    setValues({ page: 1, limit: 10, offset: 0,search:"" }); // Reset pagination
    };

  // Handle Search
  const handleSearch = (e) => {
    setValues({ page: 1, limit: 10, offset: 0,search:e.target.value})
    };


  return (
    <>
      <Header />
      <Box sx={CSS.Dashboard.box}>
        <Heading headingtext={"Projects"}/>
        <Box sx={{ display: 'flex' }}>
          <SelectField menuData={["All", "hourly", "fixed"]} def={alignment} handleChangeForSelect={handleChange} />
          <TextField
           InputProps={{
            disableUnderline: true,
            pt: '6px',
          }}
          inputProps={{
            style: {
              paddingTop: '10px',
              paddingBottom: '10px',
            }
          }}
          placeholder='Search'
            variant="filled"
            value={values.search}
            onChange={handleSearch} sx={CSS.Dashboard.search}/>
          <IconButton onClick={handleRefresh}> <RefreshIcon /></IconButton>
        </Box>
      </Box>
      <LoaderComponent open={loading} />
      <Card list={list} values={values} results={results} />
      <ArrowUpward
        onClick={scrollToTop}
        style={{
          display: visible ? 'flex' : 'none',
          background: '#4cd964',
          borderRadius: '50%',
          color: 'white',
          cursor: 'pointer',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000
        }}
      />
      {!load && (
        <Box sx={CSS.Dashboard.loader}>
          <CircularProgress
            sx={CSS.Dashboard.Progress}
          />
        </Box>
      )}
      <Toastify />
    </>
  );
}

export default Dashboard;
