import { GET_BID, GET_BID_CREATE_UPDATED, GET_BIDS, GET_COOKIE, GET_PROJECTS,GET_PROJECTS_ID } from "../ConstAction";

export const Allprojects = (data) => {
  return {
    type: GET_PROJECTS,
    data: data,
  };
};
  export const Project_by_id = (data) => {
    return {
      type: GET_PROJECTS_ID,
      data: data,
    };
};
export const bids = (data) => {
  return {
    type: GET_BIDS,
    data: data,
  };
};
export const CreateBid = (data,values) => {
  return {
    type: GET_BID_CREATE_UPDATED,
    data: data,
    values: values,
  };
};
export const Getbid = (data) => {
  return {
    type: GET_BID,
    data: data,
  };
};
export const updateCookie = (data) => {
  return {
    type: GET_COOKIE,
    data: data,
  };
};