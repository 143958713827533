import { Api } from "../../Config/Requests";
export const getprojects = async (data) => {
  ////console.log(data);
  return Api.GetRequest("projects/projects", data);
};
export const getprojectsid = async (data) => {
  ////console.log(data);
  return Api.GetRequest("projects/projects_by_id", data);
};
export const postbid = async (data) => {
  ////console.log(data);
  return Api.PostRequest("projects/bid", data);
};
export const updatebid = async (data) => {
  return Api.PostRequest("projects/updatebid", data);
};
export const getbid = async (data) => {
  return Api.GetRequest("projects/bidbyid", data);
};
export const getbids = async (data) => {
  ////console.log(data);

  return Api.GetRequest("projects/bids", data);
};
export const cookie = async (data) => {
  return Api.PostRequest("projects/cookie", data);
};
const projectsApiRequests = {
  getprojects,
  getprojectsid,
  postbid,
  updatebid,
  getbid,
  getbids,
  cookie

};
export default projectsApiRequests;
