import { Typography } from '@mui/material'
import React from 'react'
import { CSS } from './CSS'

const Heading = ({headingtext}) => {
  return (
    <Typography sx={CSS.Dashboard.heading}>{headingtext}</Typography>

  )
}

export default Heading