import { Box, Grid, Button, Container, TextField, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Toastify from "../Components/SnackBar/Toastify";
import * as yup from 'yup'
import { toast } from "react-toastify";
import { API_URL } from "../Config/URL";
import { storage } from "../Config/Storage";
import { GET_LOGIN } from "../redux/ConstAction";
import { getLoading, getLogin, getRegister } from "../redux/User/UserAction";
import { useDispatch } from "react-redux";

const defaultValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    c_password: "",
};
const Schema = {
    register: yup.object().shape({
        firstname: yup.string().required('Please enter the first name'),
        lastname: yup.string().required('Please enter the last name'),
        email: yup.string().email("Please enter a valid email").required('Please enter the email'),
        password: yup.string().required('Please enter your password.')
            .min(8, 'Password is too short - should be 8 char minimum.'),
        c_password: yup
            .string()
            .required('Confirm your password.')
            .min(8, 'Password is too short - should be 8 char minimum.')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
}
const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = (event) => {
        event.preventDefault();
        setIsFocused(false);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    useEffect(() => { }, []);

    const { control, formState, handleSubmit } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(Schema.register),
    });
    const { errors } = formState;

    const onSubmit = (model) => {
        let item = model;
        dispatch(getRegister(model, navigate))
        dispatch(getLoading(true))
    };

    const Style = {
        input: {
            border: "none",
            bordeRadius: "50px",
            "& .MuiInputBase-root": {
              
                border: isFocused ? "none" : "2px solid",
                bordeRadius: "50px",
                borderColor: " #02010100",
            },
            "& .MuiFormHelperText-root": {
                // Style the root element of MuiFormHelperText
                margin: 0,
                marginTop: 0,
                color: "red",
            },
        },
        logo: {
            color: "#7d674a",
            fontSize: "24px",
            fontWeight: 700,
        },
        logo1: {
            color: "#343568",
            fontSize: "20px",
            fontWeight: 700,
        },
        btn: {
            bgcolor: "#7d674a",
            color: "white",
            fontSize: "16px",
            width: "25%",
            "&.MuiButton-root": {
                bgcolor: "#7d674a",
                color: "white",
                fontSize: "16px",
                width: { xs: "45%", sm: "25%" },
            },
        },
    };
    return (
        <>
            <Box sx={style.outBox} className="form-screens">
                <Container maxWidth="xl" sx={{
                    "&.MuiContainer-root": {
                        pl: 0
                    }
                }}>
                    <Box sx={style.mainBox}>
                        <Box
                            sx={{
                                width: "50%",
                                height: "calc(100vh - 100px)",
                                p: "35px 40px 40px 40px",
                                display: { xs: "none", md: "flex" },
                                alignItems: "center",
                            }}>
                            <img src={require("../assets/login.jpg")} width={"80%"} />
                        </Box>
                        <Box sx={style.leftBox}>
                            <Box
                                sx={{
                                    width: "80%",
                                    pb: 5,
                                    display: "flex",
                                }}>
                                <Box>
                                    <Typography sx={Style.logo}>Sign Up</Typography>
                                </Box>
                            </Box>
                            <form
                                name="LoginForm"
                                className="loginForm"
                                onSubmit={handleSubmit(onSubmit)}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "80%",
                                    width: "80%",
                                    // justifyContent: 'space-evenly',
                                }}>
                                <Grid container columnGap={2}>
                                    <Grid xs={5}>
                                        <FormLabel sx={{ color: "#333" }}>First Name<span style={style.span}>*</span></FormLabel>
                                        <Controller
                                            name={"firstname"}
                                            control={control}
                                            sx={{
                                                mt: 10,
                                                pb: 0,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    helperText={errors?.firstname?.message}
                                                    errors={!!errors.username}
                                                    // onChange={(e) => setMessage(e.target.value)}
                                                    // value={message}
                                                    // onFocus={handleFocus}
                                                    // onBlur={handleBlur}
                                                    // onKeyDown={handleKeyDown} // Attach keydown event listener
                                                    sx={Style.input}
                                                    name="firstname"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={5}>
                                        <FormLabel sx={{ color: "#333" }}>Last Name<span style={style.span}>*</span></FormLabel>
                                        <Controller
                                            name={"lastname"}
                                            control={control}
                                            sx={{
                                                mt: 10,
                                                pb: 0,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    helperText={errors?.lastname?.message}
                                                    errors={!!errors.username}
                                                    // onChange={(e) => setMessage(e.target.value)}
                                                    // value={message}
                                                    // onFocus={handleFocus}
                                                    // onBlur={handleBlur}
                                                    // onKeyDown={handleKeyDown} // Attach keydown event listener
                                                    name="lastname"
                                                    sx={Style.input}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid xs={5}>
                                        <FormLabel sx={{ color: "#333" }}>Email<span style={style.span}>*</span></FormLabel>
                                        <Controller
                                            name={"email"}
                                            control={control}
                                            sx={{
                                                mt: 10,
                                                pb: 0,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    helperText={errors?.email?.message}
                                                    errors={!!errors.username}
                                                    // onChange={(e) => setMessage(e.target.value)}
                                                    // value={message}
                                                    // onFocus={handleFocus}
                                                    // onBlur={handleBlur}
                                                    // onKeyDown={handleKeyDown} // Attach keydown event listener
                                                    name="email"
                                                    sx={Style.input}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid>

                                    </Grid>
                                </Grid>

                                <Grid container columnGap={2}>
                                    <Grid xs={5}>
                                        <FormLabel sx={{ color: "#333" }}>Password<span style={style.span}>*</span></FormLabel>
                                        <Controller
                                            name={"password"}
                                            control={control}
                                            sx={{
                                                pb: 0,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    type="password"
                                                    name="password"
                                                    helperText={errors?.password?.message}
                                                    errors={!!errors.password}
                                                    sx={Style.input}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={5}>
                                        <FormLabel sx={{ color: "#333" }}>Confirm Password<span style={style.span}>*</span></FormLabel>
                                        <Controller
                                            name={"c_password"}
                                            control={control}
                                            sx={{
                                                pb: 0,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    onFocus={handleFocus}
                                                    onBlur={handleBlur}
                                                    type="password"
                                                    name="c_password"
                                                    helperText={errors?.c_password?.message}
                                                    errors={!!errors.password}
                                                    sx={Style.input}
                                                />
                                            )}
                                        />
                                    </Grid>

                                </Grid>


                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: { md: "flex-start" },
                                        mt: 4,
                                        mb: 2,
                                        pr: { xs: 1, md: 10 },
                                        // pl: { xs: 0, sm: 1 },
                                    }}>
                                    <Button type="submit" sx={Style.btn}>
                                        Sign up
                                    </Button>
                                    <Toastify />
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Register;

const style = {
    outBox: {
        width: "100%",
        //bgcolor: '#F3F0EE',
        overflow: "hidden",
        // pt: 5,
        // pb: 5,
    },
    mainBox: {
        // maxWidth: '1530px',
        width: "100%",
        minHeight: "500px",
        ml: "auto",
        mr: "auto",
        // p: '40px',
        bgcolor: "white",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { xs: "center", md: "flex-end" },
        alignItems: "center",
    },
    leftBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: { xs: "100%", md: "50%" },
    },
    button: {
        width: "30%",
        "&.MuiButtonBase-root:hover": {
            bgcolor: "#CB2128",
            border: "1.5px solid #FF8D2A",
        },
        bgcolor: "#CB2128",
        color: "#FFFFFF",
        border: "1.5px solid #FF8D2A",
        mb: 2,
        mt: 4,
        borderRadius: "5px",
        fontSize: { xs: "16px", md: "16px", lg: "16px" },
        fontWeight: 400,
        textTransform: "none",
        transform: "skewX(-20deg)",
        "& .MuiButton-label": {
            transform: "skewX(20deg)",
        },
    },
    span: {
        color: "#ff0000"
    },
    validationStyle: {
        color: '#d32f2f',
        margin: '10px',
        fontWeight: '500',
        fontSize: '12px',
        display: 'block', '& .MuiFormHelperText-root': {
            // Style the root element of MuiFormHelperText
            margin: 0,
            marginLeft: 0,
        },
    }
};
