import React, { useEffect } from 'react'
import { CSS } from './CSS';
import { Button, FormLabel, Grid, InputAdornment, TextareaAutosize, TextField, Typography } from '@mui/material';
import { Formik, useFormik } from 'formik';
import axios from 'axios';
import { API_URL } from '../Config/URL';
import { yupResolver } from '@hookform/resolvers/yup';

const Form = ({add,defaultValues,data,formik,handleClose}) => {
    ////console.log(formik.values);
    useEffect(() => {
   
    }, [formik])
    
  return (
    <form onSubmit={formik.handleSubmit}>
    <Typography sx={CSS.Modal.heading}>{add?"Place a Bid on this project":"Edit your Bid"}</Typography>
    <Grid container columnGap={2}>
      <Grid>
        <FormLabel >Bid Amount</FormLabel>
            <TextField
              value={formik?.values?.amount}
               name='amount'
               onChange={formik.handleChange}
              inputProps={{
                style: {
                  height: "10px",
                }
              }}
              InputProps={{
                pt: 1,
                startAdornment:
                  <InputAdornment>
                    <Typography
                      sx={{
                        '&.MuiIconButton-root': {
                          borderRadius: '0%',
                          borderLeft: '0px',
                          p: '8.5px',
                          bgcolor: '#F2F2F2',
                        },
                      }}
                      edge="end"
                    >{data?.currency?.sign}
                    </Typography>
                  </InputAdornment>,
                endAdornment:
                  <InputAdornment>
                    <Typography
                      sx={{
                        '&.MuiIconButton-root': {
                          borderRadius: '0%',
                          borderLeft: '0px',
                          p: '8.5px',
                          bgcolor: '#F2F2F2',
                        },
                      }}
                      edge="end"
                    >{data?.currency?.code}
                    </Typography>
                  </InputAdornment>
              }}
              sx={CSS.Modal.field} />
                {formik.errors.amount && formik.touched.amount ? (
            <p style={CSS.Modal.validationStyle}>{formik.errors.amount}</p>
          ) : null}
      </Grid>
      <Grid>
        <FormLabel>This project will be delivered in</FormLabel>
            <TextField
              type='number'
               name='days'
               onChange={formik.handleChange}
              value={formik?.values?.days}
              inputProps={{
                style: {
                  height: "10px",
                }
              }}
              InputProps={{
                pt: 1,
                endAdornment:
                  <InputAdornment>
                    <Typography
                      sx={{
                        '&.MuiIconButton-root': {
                          borderRadius: '0%',
                          borderLeft: '0px',
                          p: '8.5px',
                          bgcolor: '#F2F2F2',
                        },
                      }}
                      edge="end"
                    >Days
                    </Typography>
                  </InputAdornment>
              }}
              sx={CSS.Modal.field} />
                {formik.errors.days && formik.touched.days ? (
            <p style={CSS.Modal.validationStyle}>{formik.errors.days}</p>
          ) : null}
      </Grid>
    </Grid>
    <Grid>
      <FormLabel>Describe your proposal (minimum 100 characters)</FormLabel>
          <TextareaAutosize name='description' value={formik?.values?.description}style={{width:"70%"}}  onChange={formik.handleChange} minRows={7} sx={CSS.Modal.field} />
              {formik.errors.description && formik.touched.description ? (
            <p style={CSS.Modal.validationStyle}>{formik.errors.description}</p>
          ) : null}

    </Grid>
    <Grid container columnGap={2}>
      <Button sx={CSS.Modal.btn} type='submit'>Submit</Button>
      <Button sx={CSS.Modal.btn1} onClick={handleClose}>Close</Button>
    </Grid>
  </form> 
  )
}

export default Form