
export const CSS = {
    HeaderStyle : {
        img: {
            width: "75px"
        },
        navbar: {
            backgroundColor: "#7d674a",
            color: "black",
            position: "relative",
            zIndex: 3,
            position: "fixed",
            top: "0px"
        },
        navItem: {
            display: {
                xs: "flex",
                md: "flex"
            },
            // justifyContent: "end",
            flexGrow: 1,
            gap: "60px"
        },
        navLink: {
            color: "#ffffff",
            textDecoration: "none",
            textTransform: "capitalize",
            fontSize: "16px",
            paddingBottom: "3px",
            fontWeight: 500,
            "&.MuiIconButton-root":{
                padding:0
            }
        }
    },
    Dashboard: {
    box:{display:'flex',justifyContent:'space-between',ml:3,mr:4,mt:10},
    heading: {
            fontSize: "35px",
            fontWeight: 700,
        },
        loader: { display: 'flex', justifyContent: 'center' },
        Progress: {
            display: 'flex',
            overflowX: 'hidden',
            height: '60px',
            width: '60px',
        },
    search:{
        border: '1px solid #C8C8C8',
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
          },
          '&.MuiList-root': {
            height: '300px',
          },
          "&.MuiFilledInput-root":{
            height: '43px',
        },
          minWidth: '200px',
          height: '43px',
          width: '100%',
          fontSize: '16px',
          fontWeight: '400',
          ml:2
    }
    },
    Card: {
        list: { width: { xs: "auto", sm: 'auto' }, bgcolor: 'background.paper', p: 3 },
        box: { display: "flex", justifyContent: 'center' },
        box1: { display: {xs:'block', md:'flex'}, justifyContent: 'space-between', mt: 2 },
        item: { display: "block", boxShadow: "2px 2px 2px 2px", mb: 2, borderRadius: '10px', cursor: "pointer", width: "100%" },
        text: { fontSize: '24px', pb: 2, fontWeight: 700, wordBreak: "break-word", width: "87%" },
        text1: { fontSize: '16px', pb: 2 }

    },
    Modal: {
        modal: {
            padding: 2, backgroundColor: 'white', position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: "80%",
            maxHeight: '500px',
            overflow: 'scroll',
            // p: 2,
            borderRadius: 2,
        },
        box: { display: 'flex', justifyContent: "space-between", mt: 2 },
        head: { display: 'flex', justifyContent: "center", fontSize: "14px" },
        value: { display: 'flex', justifyContent: "center", fontSize: '26px', fontWeight: 600 },
        btn: {
            textTransform: 'none', bgcolor: "#4cd964", color: 'white', mt: 2, width: "100px", "&.MuiButton-root:hover": {
                bgcolor: "#4cd964"
            }
        },
        btn1: {
            textTransform: 'none', bgcolor: "red", color: 'white', mt: 2, width: "100px", "&.MuiButton-root:hover": {
                bgcolor: "red"
            }
        },
        heading: { fontSize: "20px", fontWeight: 600 },
        field: {
            display: 'block', '& .MuiFormHelperText-root': {
                // Style the root element of MuiFormHelperText
                margin: 0,
                marginLeft: 0,
            }, '& input[type=number]': {
                '-moz-appearance': 'textfield',
                '&::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
            },
        },
        validationStyle:{
            color: '#d32f2f',
            margin: '10px',
            fontWeight: '500',
            fontSize: '12px',
        },
       
    },
    Bids: {
        Export:{
            color:"white",
            backgroundColor:"green",
            padding:"0px 20px",
            display:"flex",
            alignItems:"center",
        }
    },
    Settings :{
        box:{
            m:3,
            display:"flex"
        }
    }
}