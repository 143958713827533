import { createBrowserRouter } from "react-router-dom";
import SignIn from "../Pages/SignIn";
import Dashboard from "../Pages/Dashboard";
import ProtectedRoute from "./protectedRoutes";
import OpenRoutes from "./OpenRoutes";
import Mybids from "../Pages/Mybids";
import Register from "../Pages/Register";
import Settings from "../Pages/Settings";
// define all the routes
const router = createBrowserRouter([
    {
        path: "/dashboard",
        element: (<ProtectedRoute><Dashboard /></ProtectedRoute>)
    },
    {
        path: "/",
        element: (
            <OpenRoutes>
                <SignIn />
            </OpenRoutes>
        )
    },
    {
        path: "/settings",
        element: (
            <ProtectedRoute>
                <Settings />
            </ProtectedRoute>
        )
    },
    {
        path: "/allbids",
        element: (<ProtectedRoute><Mybids/></ProtectedRoute>)
    },
    
]);
export default router