import { AppBar, Box, Container, Drawer, IconButton, ToggleButton, ToggleButtonGroup, Toolbar, List, ListItem, ListItemText } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { routesData } from "../Config/Mockdata";
import { CSS } from "./CSS";
import { storage } from "../Config/Storage";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const [alignment, setAlignment] = useState(storage.fetch.accountused()===undefined?'Bhawan':storage.fetch.accountused());

    useEffect(() => {
        storage.set.accountused(storage.fetch.accountused()===undefined?'Bhawan':storage.fetch.accountused());
    }, []);

    const handleLogout = () => {
        storage.destory.auth();
        navigate("/");
        setTimeout(() => {
            toast.success("User Logout Successfully");
        }, 2000);
    };

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        storage.set.accountused(event.target.value);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const drawerList = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {routesData.map((elem, index) => (
                    <ListItem button component={NavLink} to={elem.path} key={index} onClick={toggleDrawer(false)}>
                        <ListItemText primary={elem.link} />
                    </ListItem>
                ))}
                <ListItem button onClick={handleLogout}>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <AppBar position="static" sx={CSS.HeaderStyle.navbar}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>

                        <Box sx={{ ...CSS.HeaderStyle.navItem, display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
                            {routesData.map((elem, index) => (
                                <NavLink 
                                    to={elem.path} 
                                    key={index} 
                                    style={({ isActive }) => ({
                                        textDecoration: isActive ? 'underline' : 'none',
                                        color:'white',
                                        padding: '0 10px'
                                    })}
                                >
                                    {elem.link}
                                </NavLink>
                            ))}
                        </Box>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ display: { xs: 'flex', md: 'none' }, color: 'white' }}
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>

                        {/* Drawer for Mobile */}
                        <Drawer
                            anchor="left"
                            open={isDrawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            {drawerList()}
                        </Drawer>

                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            sx={{ display: { xs: 'flex', md: 'flex' }, ml: { xs: 'auto', md: '0' }, mr: { xs: '0', md: 2 } }}
                        >
                            <ToggleButton value="OOCPL" sx={{ color: "white", "&.MuiToggleButton-root.Mui-selected": { bgcolor: 'black', color: "white" } }}>OOCPL</ToggleButton>
                            <ToggleButton value="Bhawan" sx={{ color: "white", "&.MuiToggleButton-root.Mui-selected": { bgcolor: 'black', color: "white" } }}>Bhawan</ToggleButton>
                        </ToggleButtonGroup>

                        <IconButton sx={{ ...CSS.HeaderStyle.navLink, display: { xs: 'none', md: 'flex' } }} onClick={handleLogout}>Logout</IconButton>

                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};

export default Header;
