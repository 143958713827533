import { Api } from "../../Config/Requests";
export const Login = async (data) => {
  ////console.log(data);
  
  return Api.PostRequest("users/login", data);
};
export const register = async (data) => {
  return Api.PostRequest("users/register", data);
};
export const users = async (data) => {
  return Api.GetRequest("users/users");
};

const UserApiRequests = {
  Login,
  register,
  users,
};
export default UserApiRequests;
