export const routesData = [
  
    {
        path: "/dashboard",
        link: "Dashboard"
    },
    {
        path: "/allbids",
        link: "All Bids"
    },
    {
        path: "/settings",
        link: "Settings"
    }
]